import Cookies from 'js-cookie';
import lottie from 'lottie-web/build/player/lottie_light';

export default class SplashScreen {
    constructor(node) {
        this.node = node;
        this.wrapperNode = this.node.querySelector('[data-ref="wrapper"]');

        this.name = 'maestro_arts_splash_screen_seen';
        this.expiry = 1;
        this.animationPath = this.node.dataset.animationPath;
        this.onDocumentKeyUp = this.handleDocumentKeyUp.bind(this);

        document.addEventListener('keyup', this.onDocumentKeyUp);
        this.startAnimation();
        this.setSeenCookie();
        this.startFallbackTimeout();
    }

    handleDocumentKeyUp(event) {
        if (event.key === 'Escape') this.close();
    }

    clearFallbackTimeout() {
        window.clearTimeout(this.fallbackTimeout);
    }

    close() {
        this.clearFallbackTimeout();
        document.removeEventListener('keyup', this.onDocumentKeyUp);
        this.node.setAttribute('hidden', true);
    }

    setSeenCookie() {
        Cookies.set(this.name, 'true', {
            expires: this.expiry,
            secure: window.location.protocol === 'https',
        });
    }

    startAnimation() {
        const animation = lottie.loadAnimation({
            container: this.wrapperNode,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: this.animationPath,
            rendererSettings: {
                viewBoxOnly: true,
            },
        });
        animation.addEventListener('complete', this.close.bind(this));
        animation.addEventListener('data_failed', this.close.bind(this));
        animation.addEventListener('data_ready', this.clearFallbackTimeout.bind(this));
    }

    startFallbackTimeout() {
        this.fallbackTimeout = window.setTimeout(() => this.close(), 5000);
    }
}
